import Question from './question';

const tests = {
  id: '1',
  name: 'Sample Test',
  date: '2024-06-20',
  time: '10:00 AM',
  duration: '3 hours',
  canTakeAnytime: false,
  level: 'Medium',
  tag: 'JEE Mains',
  subjects: {
    math: [
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),

      // Add more questions as needed
    ],
    physics: [
        new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
        new Question('What is 2+3?', null, '4', true, 'Easy', 30),
        new Question('What is 2+4?', null, '4', false, 'Easy', 30),
        new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
        new Question('What is 2+3?', null, '4', true, 'Easy', 30),
        new Question('What is 2+4?', null, '4', false, 'Easy', 30),
        new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
        new Question('What is 2+3?', null, '4', true, 'Easy', 30),
        new Question('What is 2+4?', null, '4', false, 'Easy', 30),
        new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
        new Question('What is 2+3?', null, '4', true, 'Easy', 30),
        new Question('What is 2+4?', null, '4', false, 'Easy', 30),
        new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
        new Question('What is 2+3?', null, '4', true, 'Easy', 30),
        new Question('What is 2+4?', null, '4', false, 'Easy', 30),
        new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
        new Question('What is 2+3?', null, '4', true, 'Easy', 30),
        new Question('What is 2+4?', null, '4', false, 'Easy', 30),
        new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
        new Question('What is 2+3?', null, '4', true, 'Easy', 30),
        new Question('What is 2+4?', null, '4', false, 'Easy', 30),
        new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
        new Question('What is 2+3?', null, '4', true, 'Easy', 30),
        new Question('What is 2+4?', null, '4', false, 'Easy', 30),
        new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
        new Question('What is 2+3?', null, '4', true, 'Easy', 30),
        new Question('What is 2+4?', null, '4', false, 'Easy', 30),
        new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
        new Question('What is 2+3?', null, '4', true, 'Easy', 30),
        new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      // Add more questions as needed
    ],
    chemistry: [
      // Add more questions as needed
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),
      new Question('Answer the Following question?', 'https://qph.cf2.quoracdn.net/main-qimg-90efd2c54559618e89cac5264784b831', '4', true, 'Easy', 30),
      new Question('What is 2+3?', null, '4', true, 'Easy', 30),
      new Question('What is 2+4?', null, '4', false, 'Easy', 30),

    ],
  },
};

export default tests;
